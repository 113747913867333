import { IonApp, setupIonicReact, useIonLoading, IonToolbar, IonHeader, IonTitle, useIonAlert, IonContent, IonIcon, IonText, useIonToast, IonButton } from '@ionic/react';
import { useState, useEffect } from 'react'
import { happyOutline, sadOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be comme<p>text</p>nted out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css'

// Data

import { getProduct } from './data/parseProduct';
import { FormData, PersonData, ProductData, ProductInfo, ReturnRecords } from './data/interfaces'
import { getFormStatus, submitForm } from './data/airtable';
// Pages
import Front from './pages/Front'
import Product from './pages/Product'
import Manual from './pages/Manual'
import PersonInfo from './pages/PersonInfo'

setupIonicReact();
const App: React.FC = () => {
	// Initial States
	const initFormData = {
		productData: {
			url: '',
			productId: '',
			quantity: 1,
			option: [],
			comment: ''
		},
		personData: {
			name: '',
			email: ''
		},
		readyToSubmit: false,
		submitted: false
	}
	const initProductInfo = {
		id: '',
		url: '',
		imgs: [],
		title: '',
		price: '',
	}
	// States
	const [presentLoading, dismissLoading] = useIonLoading();
	const [step, setStep] = useState<number>(0);
	const [productInfo, setProductInfo] = useState<ProductInfo>(initProductInfo);
	const [formDetail, setFormDetail] = useState<ReturnRecords>();
	const [formData, setFormData] = useState<FormData>(initFormData)
	const [toast] = useIonToast();

	// Effects
	// Effect for checking if formData is ready to submit
	useEffect(() => {
		if (formData.readyToSubmit && !formData.submitted) {
			// console.log(formData);
			if (formDetail && formDetail.formId) {
				presentLoading({
					message: '交緊個form',
					duration: 2000
				})
				submitForm(formDetail.formId, formData).then(success => {
					if (success) {
						setFormData(prevState => {
							return {
								...prevState,
								submitted: true
							}
						})
					} else {
						toast({
							message: '唔知點解交唔到，等收得好啲再試下交過',
							duration: 2000,
							position: 'bottom'
						})
					}
				}).finally(() => {
					dismissLoading();
				})
			}

		}
	}, [formData])

	// Initial loading Effects
	useEffect(() => {
		presentLoading({
			message: '睇緊有冇開到團',
			duration: 2000
		});
		getFormStatus().then(res => {
			console.log(res);
			setFormDetail(res)
		}).finally(() => {
			dismissLoading();
		})
		dismissLoading();
	}, [])
	// Functions 
	const handleUrlSubmit = async (data: string) => {
		await presentLoading({
			message: '幫緊你、等一等',
			duration: 3000
		});
		let item = await getProduct(data);
		console.info(item);
		dismissLoading();
		if (item.status === 'success') {
			setProductInfo((item.data as ProductInfo));
			setStep(1);
		} else {
			setProductInfo((prevState) => {
				return {
					...prevState,
					url: data
				}
			})
			showRestartAlert();
		}
	}

	const handleProductSubmit = (productData: ProductData) => {
		setFormData(prevState => {
			return {
				...prevState,
				productData: productData
			}
		})
		setStep(2);
	}

	const handleFinalSubmit = (personData: PersonData) => {
		setFormData(prevState => {
			return {
				...prevState,
				personData: personData,
				readyToSubmit: true
			}
		})
	}

	const retryInput = () => {
		setStep(0);
		setProductInfo(initProductInfo);
		setFormData(initFormData);
	}
	// Retry input function
	const [presentAlert] = useIonAlert();
	const showRestartAlert = () => {
		presentAlert({
			header: 'Load 唔到你要嘅款?',
			subHeader: '可能入錯條link 或者個嘢壞咗',
			buttons: [{
				text: '再試下過入',
				role: 'retry',
				handler: retryInput
			},
			{
				text: '手動輸入',
				role: 'manual',
				handler: () => setStep(1.5)
			}]
		})
	}
	return (
		<IonApp>
			<IonHeader>
				<IonToolbar>
					<IonTitle>訂購泳褲表格</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent class="ion-padding">
				<div>
					{formDetail && formDetail.status === 'success' && !formData.submitted &&
						<div>
							{step === 0 &&
								<div>
									<IonText>
										<h3>{formDetail.data.Title}</h3>
										{/* <p>{formDetail.data.Description}</p> */}
										<p dangerouslySetInnerHTML={{__html: formDetail.data.Description}}></p>
										<p>截止時間: {new Date(Date.parse(formDetail.data.Deadline)).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })}</p>
									</IonText>
									<Front handleUrlSubmit={handleUrlSubmit} />
								</div>
							}
							{step === 1 && <Product showRestartAlert={showRestartAlert} productInfo={productInfo} handleProductSubmit={handleProductSubmit} />}
							{step === 1.5 && <Manual url={productInfo.url} handleProductSubmit={handleProductSubmit} />}
							{step === 2 && <PersonInfo handleFinalSubmit={handleFinalSubmit} />}
						</div>
					}
					{formData.submitted &&
						<div className='jumbo ion-justify-content-center ion-wrap ion-align-items-center ion-text-center'>
							<IonIcon icon={happyOutline} class="big-icon" color="success"></IonIcon>
							<h3>已經交咗啦，可以等收泳褲鳥</h3>
							<IonButton onClick={retryInput}>再買多條</IonButton>
							<IonButton fill="clear" color="medium" onClick={()=>window.close()}>冇錢鳥</IonButton>
						</div>
					}
					{formDetail && formDetail.status === 'fail' &&
						<div className='jumbo ion-justify-content-center ion-wrap ion-align-items-center ion-text-center'>
							<IonIcon icon={sadOutline} class="big-icon" color="medium"></IonIcon>
							<h3>暫時搵唔到團或者個團已經過咗截止時間</h3>
							<p>請自行搵人同你開團</p>
						</div>
					}
				</div>

			</IonContent>
		</IonApp>
	)
}

export default App;
