import { useState } from 'react';
import React from 'react';
import {
    IonContent,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonNote,
} from '@ionic/react';

import { ProductData } from '../data/interfaces';

interface ManualComponent {
    url: string,
    handleProductSubmit: (param: ProductData) => void
}
const Manual: React.FC<ManualComponent> = (props) => {
    // States
    const [productData, setProductData] = useState<ProductData>({
        url: props.url,
        productId: '',
        option: [{
            key: '尺碼',
            value: ''
        },
        {
            key: '款式',
            value: ''
        }],
        quantity: 1,
        comment: ''
    })
    // Function
    const handleInput = (event: Event) => {
        let target = (event.target as HTMLInputElement)
        if (target.name.includes('option')) {
            const index = target.name.match(/\d$/);
            if (index) {
                setProductData((prevState) => {
                    let newOptions = [...prevState.option];
                    newOptions[Number(index[0])].value = target.value
                    return {
                        ...prevState,
                        newOptions
                    }
                })
            }
        } else {
            setProductData((prevState) => {
                return {
                    ...prevState,
                    [target.name]: target.value
                }
            })
        }
    }
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        props.handleProductSubmit(productData);
    }
    return (
        <form onSubmit={handleSubmit}>
            <IonList>
                <IonItem>
                    <IonLabel position="fixed">產品連結</IonLabel>
                    <IonInput value={productData.url} type="url" name="url" onIonChange={handleInput}></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="fixed">產品編號</IonLabel>
                    <IonInput value={productData.productId} type="text" name="productId" onIonChange={handleInput} required></IonInput>
                    <IonNote slot="helper">記得double check 返條link</IonNote>
                </IonItem>
                <IonItem>
                    <IonLabel position="fixed">呎碼</IonLabel>
                    <IonInput value={productData.option[0].value} type="text" name="option-0" onIonChange={handleInput} required></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="fixed">款式</IonLabel>
                    <IonInput value={productData.option[1].value} type="text" name="option-1" onIonChange={handleInput}></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="fixed">數量</IonLabel>
                    <IonInput value={productData.quantity} type="number" min='1' max='5' name="quantity" onIonChange={handleInput}></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">備注</IonLabel>
                    <IonInput value={productData.comment} type="text" name="comment" onIonChange={handleInput}></IonInput>
                </IonItem>
            </IonList>
            <IonButton type="submit">下一步</IonButton>
        </form>
    )
}

export default Manual;