import axios from 'axios';
import { ReturnProduct, ProductOptions } from '../data/interfaces'

const cors = 'https://cors.freehi.workers.dev/?';

export const getProduct = async (url: string): Promise<ReturnProduct> => {
    const site = new URL(url);
    let returnProduct: ReturnProduct = { status: 'fail' };
    if (site.hostname.includes('rakuten.co.jp')) {
        returnProduct = await parseRakuten(url);
    } else if (site.hostname.includes('aqux.ocnk.net')) {
        returnProduct = await parseAqux(url);
    } else if (site.hostname.includes('surfblade.shop')) {
        returnProduct = await parseSurfBlade(url);
    } else if (site.hostname.includes('kyouei-mizugi.com')) {
        returnProduct = await parseKyouei(url);
    } else if (site.hostname.includes('myprotein.com')){
        returnProduct = await parseMyProtein(url);
    }
    else {
        return returnProduct
    }
    return returnProduct
}

async function parseRakuten(url: string): Promise<ReturnProduct> {
    try {
        let colorOptionsDom, colorOptions, sizeOptionsDom, sizeOptions;
        let options: ProductOptions[] = []
        const response = await axios.get(cors + url);
        const productPage = new DOMParser().parseFromString(response.data, 'text/html');
        let productName = productPage.querySelector('span.item_name b')?.textContent;
        if (!productName) { //Mobile version
            productName = productPage.querySelector('[class*=html-host]')?.textContent
        }
        let productImgNodes: NodeListOf<HTMLImageElement> = productPage.querySelectorAll('.sale_desc img');
        if (productImgNodes.length == 0) { //Mobile version
            productImgNodes = productPage.querySelectorAll('div[class*=item-description] > table img')
        }
        const imgUrls = Array.from(productImgNodes).map(img => img.src);
        let price = productPage.querySelector('span.price2')?.textContent;
        if (!price) { //Mobile version
            price = productPage.querySelector('[class*=price]')?.textContent
        }
        const productId = productPage.querySelector('span.item_number')?.textContent;
        const sizeChart = productPage.querySelector('td.floating-cart-sku-table table');
        const sizeChartString = sizeChart ? new XMLSerializer().serializeToString(sizeChart) : null;
        // Extract options
        if (sizeChart) {
            // Size options from first row
            sizeOptionsDom = sizeChart.querySelectorAll('tr:first-of-type > td > span.inventory_choice_name')
            sizeOptions = Array.from(sizeOptionsDom).map(size => size.textContent);
            if (sizeOptions) {
                options.push({ key: '款式 / 呎碼', value: (sizeOptions as string[]) });
            }
            // color options from first column
            colorOptionsDom = sizeChart.querySelectorAll('tr td:first-of-type > span.inventory_choice_name');
            if (colorOptionsDom.length > 1) {
                colorOptions = Array.from(colorOptionsDom).map(color => color.textContent);
                if (colorOptions) {
                    options.push({ key: '款式 / 呎碼', value: (colorOptions as string[]) });
                }
            }
        }
        return {
            status: 'success',
            data: {
                id: productId as string,
                url: url,
                imgs: imgUrls,
                title: productName as string,
                price: price as string,
                options: options,
                sizeChart: sizeChartString
            }
        }
    } catch (err) {
        console.error(err);
        return { status: 'fail' }
    }
}

async function parseAqux(url: string): Promise<ReturnProduct> {
    try {
        let sizeOptions, sizeOptionsDom
        let options: ProductOptions[] = []
        const response = await axios(cors + url);
        const productPage = new DOMParser().parseFromString(response.data, 'text/html');
        const productName = productPage.querySelector('span.goods_name')?.textContent;
        const productImgNodes: NodeListOf<HTMLImageElement> = productPage.querySelectorAll('img.vertical_image, img.horizontal_image');
        const imgUrls = Array.from(productImgNodes).map(img => img.src);
        const price = productPage.querySelector('#pricech')?.textContent;
        const productId = productPage.querySelector('.model_number_value')?.textContent;
        let sizeChart = productPage.querySelector('#variation_list_item');
        let sizeChartString = sizeChart ? new XMLSerializer().serializeToString(sizeChart) : null;
        // Extract options from size chart
        if (sizeChart) {
            sizeOptionsDom = sizeChart.querySelectorAll('tbody th');
            sizeOptions = Array.from(sizeOptionsDom).map(size => size.textContent?.replaceAll('\n', ''));
            if (sizeOptions) {
                options.push({ key: '尺碼', value: (sizeOptions as string[]) })
            }
        }
        return {
            status: 'success',
            data: {
                id: productId as string,
                url: url,
                imgs: imgUrls,
                title: productName as string,
                price: price as string,
                options: options,
                sizeChart: sizeChartString
            }
        }
    } catch (err) {
        console.error(err);
        return { status: 'fail' }
    }
}


async function parseSurfBlade(url: string): Promise<ReturnProduct> {
    try {
        let sizeOptionsDom, sizeOptions, colorOptionsDom, colorOption
        let options: ProductOptions[] = []
        const response = await axios(cors + url);
        const productPage = new DOMParser().parseFromString(response.data, 'text/html');
        const productName = productPage.querySelector('h2.p-product-info__ttl')?.textContent?.replaceAll('\n', '').replaceAll(' ', '');
        const productImgNodes: NodeListOf<HTMLImageElement> = productPage.querySelectorAll('.p-product-img__main-item img');
        const imgUrls = Array.from(productImgNodes).map(img => img.src);
        const price = productPage.querySelector('.p-product-price__sell')?.textContent?.replaceAll('\n', '').replaceAll(' ', '');
        const productId = productPage.querySelector('.p-product-info__id')?.textContent?.replaceAll('\n', '').replaceAll(' ', '');
        let sizeChart = productPage.querySelector('#option_tbl');
        // Remove texts from surfblade size chart
        let table_price = sizeChart?.querySelectorAll('span.table_price');
        if (table_price) {
            for (let el of table_price) {
                el.remove();
            }
        }
        let labels = sizeChart?.querySelectorAll('label');
        if (labels) {
            for (let el of labels) {
                el.remove();
            }
        }
        let stock_zero = sizeChart?.querySelectorAll('span.stock_zero');
        if (stock_zero) {
            for (let el of stock_zero) {
                el.textContent = '✕';
            }
        }
        let stock_num = sizeChart?.querySelectorAll('span.t_stock_num');
        if (stock_num) {
            for (let el of stock_num) {
                el.remove();
            }
        }
        const sizeChartString = sizeChart ? new XMLSerializer().serializeToString(sizeChart) : null;
        // Read Values from size chart
        if (sizeChart) {
            // Read size from first row
            sizeOptionsDom = sizeChart?.querySelectorAll('tr:first-of-type th');
            sizeOptions = sizeOptionsDom ? Array.from(sizeOptionsDom).filter(size => size.textContent).map(size => size.textContent) : undefined;
            if (sizeOptions) {
                options.push({ key: '尺碼', value: (sizeOptions as string[]) })
            }
            // Read color from first column
            colorOptionsDom = sizeChart?.querySelectorAll('tr > th:first-child');
            if (colorOptionsDom.length > 1) {
                colorOption = colorOptionsDom ? Array.from(colorOptionsDom).filter(color => color.textContent).map(color => color.textContent) : undefined;
                if (colorOption) {
                    options.push({ key: '款式', value: (colorOption as string[]) })
                }
            }
        } else {
            options = [];
        }

        if (productId || productName || price) {
            return {
                status: 'success',
                data: {
                    id: productId as string,
                    url: url,
                    imgs: imgUrls,
                    title: productName as string,
                    price: price as string,
                    options: options,
                    sizeChart: sizeChartString
                }
            }
        } else {
            return {
                status: 'fail',
                data: {
                    id: productId as string,
                    url: url,
                    imgs: imgUrls,
                    title: productName as string,
                    price: price as string,
                    options: options,
                    sizeChart: sizeChartString
                }
            }
        }
    } catch (err) {
        console.error(err);
        return { status: 'fail' }
    }
}

async function parseKyouei(url: string): Promise<ReturnProduct> {
    try {
        const response = await axios(cors + url);
        const productPage = new DOMParser().parseFromString(response.data, 'text/html');
        const productName = productPage.querySelector('form div:first-of-type')?.textContent;
        const productImgNodes: NodeListOf<HTMLImageElement> = productPage.querySelectorAll('.new-images img');
        const imgUrls = Array.from(productImgNodes).map(img => img.src);
        const price = productPage.querySelector('.sales td')?.textContent;
        const productId = productPage.querySelector('.spec tr:first-of-type td')?.textContent;
        let sizeChart = productPage.querySelector('.stock');
        const sizeChartString = sizeChart ? new XMLSerializer().serializeToString(sizeChart) : null;
        // Extract options from select manual
        const productOptions: NodeListOf<HTMLOptionElement> = productPage.querySelectorAll('select option');
        const optionValues = Array.from(productOptions).map(option => option.textContent);
        return {
            status: 'success',
            data: {
                id: productId as string,
                url: url,
                imgs: imgUrls,
                title: productName as string,
                price: price as string,
                options: [{ key: '尺碼', value: optionValues as string[] }],
                sizeChart: sizeChartString
            }
        }
    } catch (err) {
        console.error(err);
        return { status: 'fail' }
    }
}

async function parseMyProtein(url: string): Promise<ReturnProduct> {
    try {
        const site = new URL(url);
        const response = await axios(cors + url);
        const productPage = new DOMParser().parseFromString(response.data, 'text/html');
        const productName = productPage.querySelector('.productName_title')?.textContent;
        const productImgNodes: NodeListOf<HTMLImageElement> = productPage.querySelectorAll('img.athenaProductImageCarousel_image');
        const imgUrls = [productImgNodes[0].src];
        const price = productPage.querySelector('.productPrice_price')?.textContent;
        const productId = site.pathname;
        const optionNodes = productPage.querySelectorAll('ul.athenaProductVariations_list');
        let options: ProductOptions[] = []
        if (optionNodes){
            for (let node of Array.from(optionNodes)){
                let variations = Array.from(node.querySelectorAll('li > button > span')).map(element => element.textContent);
                if (variations.length == 1) {
                    variations = Array.from(node.querySelectorAll('li > button')).map(element => element.textContent);
                }
                console.log(variations);
                let filteredText = variations.map(text => {
                    const matchArray = text?.match('\n.*\n');
                    if (matchArray){
                        return matchArray[0].replace(/\\n +/, '').replace('\n', '');
                    }
                });
                options.push({ key: '選項', value: filteredText as string[]});
            }
        }
        return {
            status: 'success',
            data: {
                id: productId as string,
                url: url,
                imgs: imgUrls,
                title: productName as string,
                price: price as string,
                options: options,
            }
        }
    } catch (err) {
        console.error(err);
        return { status: 'fail' }
    }
}