import { useState } from 'react';
import React from 'react';
import {
    IonContent,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonNote,
} from '@ionic/react';

const Manual: React.FC<{handleFinalSubmit: any }> = (props) => {
    // States
    const [personData, setPersonData] = useState({
        name: '',
        email: ''
    })
    // Function
    const handleInput = (event: Event) => {
        let target = (event.target as HTMLInputElement)
        setPersonData((prevState) => {
            return {
                ...prevState,
                [target.name]: target.value
            }
        })
    }
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        props.handleFinalSubmit(personData);
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <IonList>
                    <IonItem>
                        <IonLabel position="fixed">名稱</IonLabel>
                        <IonInput value={personData.name} type="text" name="name" onIonChange={handleInput} required></IonInput>
                        <IonNote slot="helper">唔知你係邊個俾唔到泳褲你</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="fixed">E-mail</IonLabel>
                        <IonInput value={personData.email} type="email" name="email" onIonChange={handleInput} required></IonInput>
                    </IonItem>
                </IonList>
                <IonButton type="submit">提交</IonButton>
            </form>
        </div>
    )
}

export default Manual;