import Airtable from 'airtable';
import axios from 'axios';
import { ReturnRecords, FormData } from './interfaces';

const token = 'patzTLdnAnJuHseOW.e8f8af1420354298fd65b0734ce8a8cc8a89777ee75b7b2493a6a563488eb737';
const baseId = 'app8I8c4Kk91RmOqB'
const controlForm = 'tblsUEyA7kUsBQmem'
Airtable.configure({
    apiKey: token
});
const base = Airtable.base(baseId);

export async function getFormStatus(): Promise<ReturnRecords> {
    // Get Record from controller first
    const returnData = await getForm();
    if (returnData.status === 'fail' || !returnData){
        return {
            status: 'fail',
            data: { message: 'Unable to get table from airtable. Please check baseId / token'}
        }
    }
    // Check if input formId is valid
    const inputFormId = returnData.data.FormId
    const isFormIdValid = await formIdValid(inputFormId);
    if ( !isFormIdValid ){
        return {
            status: 'fail',
            data: { message: 'Cannot get form pointed from FormId field. Please double check FormId field'}
        }
    }
    const internetTime = await axios('https://worldtimeapi.org/api/timezone/Asia/Taipei');
    const currentTime = new Date(Date.parse(internetTime.data.datetime));
    const deadlineTime = new Date(Date.parse(returnData.data.Deadline));
    // Check if current time passed deadline
    if ( currentTime > deadlineTime){
        return {
            status: 'fail',
            data: { message: 'Form expired. Please check deadline time'}
        }
    }
    return {
        ...returnData,
        formId: inputFormId
    }
}

async function getForm(): Promise<ReturnRecords> {
    const form = base(controlForm).select({ //Fetch record of latest deadline
        view: 'Grid view',
        sort: [{ field: "Deadline", direction: "desc" }],
        maxRecords: 1,
    })
    try {
        const res = await form.firstPage();
        const data = res[0].fields
        return {
            status: 'success',
            data: data
        }
    } catch (err) {
        return {
            status: 'fail',
        }
    }
}

const formIdValid = async (formId: string): Promise<boolean> => {
    const form = base(formId).select({
        view: 'Grid view',
        maxRecords: 1
    })
    try {
        const res = await form.firstPage();
        if (res) {
            return true
        }
        return false
    } catch (err) {
        return false
    }
}

export async function submitForm(inputFormId: string, data: FormData){
    let optionString: string | string[] = data.productData.option.map(pair => {
        return `${pair.key}: ${(pair.value as string).replaceAll(' ', '')}`
    })
    optionString = optionString.join('; ')
    const submitFields = {
      "Name": data.personData.name,
      "Email": data.personData.email,
      "Product URL": data.productData.url,
      "Product ID": data.productData.productId,
      "Product Options": optionString,
      "Quantity": Number(data.productData.quantity),
      "Comment": data.productData.comment
    }
    try{
        const form = await base(inputFormId).create([{
            fields: submitFields
        }])
        if (form){
            return true
        }
    } catch (err){
        console.error(err);
        return false
    }
}