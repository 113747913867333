import { useEffect, useState } from 'react';
import React from 'react';
import { ProductOption, ProductOptions } from '../data/interfaces';
import { nanoid } from 'nanoid'
import {
    IonList,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup
} from '@ionic/react';

const OptionList: React.FC<{options: ProductOptions[] | undefined, handleOptionChange: any, optionState: ProductOption[]}> = (props) => {
    // States
    const generateTemplate = (): ProductOption[] => {
		if(props.options){
			return props.options?.map(option => {
				return {
					key: option.key,
					value: ''
				}
			})
		} else {
			return []
		}
    }
    const selectTemplate = generateTemplate();
    const [selectOption, setSelectOption] = useState(selectTemplate);

    // Functions
    const handleLocalOptionChange = (value: string | number, index: number) => {
		let newState;
        setSelectOption((prevState) => {
			newState = [...prevState];
			newState[index].value = value;
            return newState
        })
		props.handleOptionChange(value, index);
    }

    // Create List
    const listElement = props.options?.map((option, index) =>{
        return (
            <div key={nanoid()}>
                <h3>請選擇{option.key}:</h3>
                <IonList>
                    <IonRadioGroup onIonChange={props.handleOptionChange} value={props.optionState[index].value} name={"option" + index}>
                        {option.value?.map(value =>
                            <IonItem key={nanoid()}>
                                <IonLabel>{value}</IonLabel>
                                <IonRadio slot="end" value={value}></IonRadio>
                            </IonItem>
                        )}
                    </IonRadioGroup>
                </IonList>
            </div>
        )
    })
    return(
        <div className="option-wrapper">
            {listElement}
        </div>
    )
}
export default OptionList