import { useEffect, useState } from 'react';
import React from 'react';
import {
	IonContent,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonInput,
	IonList,
	IonItem,
	IonLabel,
	IonButton,
	IonNote,
	useIonToast,
} from '@ionic/react';
import { nanoid } from 'nanoid';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

// Data
import { ProductInfo, ProductData, ProductOption } from '../data/interfaces';
import OptionList from '../components/OptionList'
import './Product.css';

interface ProductComponent {
	productInfo: ProductInfo,
	showRestartAlert: () => void,
	handleProductSubmit: (para: ProductData) => void
}

const Product: React.FC<ProductComponent> = (props) => {
	const initProductState = (): ProductData => {
		let productOptions: ProductOption[] = [];
		if (props.productInfo.options && props.productInfo.options.length > 0) {
			productOptions = props.productInfo.options?.map(option => {
				return {
					key: option.key,
					value: ''
				}
			})
		}
		return {
			url: props.productInfo.url,
			productId: props.productInfo.id,
			quantity: 1,
			option: productOptions,
			comment: ''
		}
	}

	// States
	const [confirmed, setConfirmed] = useState<boolean>(false);
	const [productData, setProductData] = useState<ProductData>(initProductState());

	// JSX for gallery
	const gallery = props.productInfo.imgs.map(url => <SwiperSlide key={nanoid()}><img src={url} /></SwiperSlide>)

	// Functions
	const [toast] = useIonToast();
	const presentToast = () => {
		toast({
			message: '記得揀哂呎碼同款式呀',
			duration: 1500,
			position: 'bottom',
		})
	}
	const handleConfirm = () => {
		setConfirmed(true);
	}
	const handleInput = (event: Event) => {
		const target = event.target as HTMLInputElement;
		setProductData((prevState) => {
			return {
				...prevState,
				[target.name]: target.value
			}
		})
	}
	const handleOptionChange = (event: Event) => {
		const target = event.target as HTMLInputElement;
		const index = target.name.match(/\d$/);
		let newOption = [...productData.option];
		if (index) {
			newOption[Number(index[0])].value = target.value;
		}
		setProductData((prevState) => {
			return {
				...prevState,
				option: newOption
			}
		})
	}
	const handleFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		for (let option of productData.option) {
			if (option.value === '') {
				presentToast()
				return
			}
		}
		props.handleProductSubmit(productData);
	}
	// Markup for chartsize 
	const markup = { __html: props.productInfo.sizeChart };

	return (
		<div>
			<IonCard className='ion-padding'>
				<Swiper pagination={true} modules={[Pagination]} className="productGallery">
					{gallery}
				</Swiper>
				<IonCardHeader>
					<IonCardSubtitle>{props.productInfo.price}</IonCardSubtitle>
					<IonCardSubtitle>{props.productInfo.id}</IonCardSubtitle>
					<IonCardTitle>{props.productInfo.title}</IonCardTitle>
				</IonCardHeader>
				{props.productInfo.sizeChart &&
					<div>
						<h5>庫存狀況</h5>
						<IonCardContent>只供參考，請到產品網站確認<br />唔好係度揀款式同尺碼</IonCardContent>
						<div className="table-wrapper" dangerouslySetInnerHTML={markup} />
					</div>
				}
				{confirmed == false &&
					<div>
						<IonCardContent>係唔係你揀果款呢？</IonCardContent>
						<div className='button-container'>
							<IonButton className="confirm-button" onClick={handleConfirm}>係</IonButton>
							<IonButton className="confirm-button" onClick={props.showRestartAlert}>唔係</IonButton>
						</div>
					</div>
				}

			</IonCard>
			{/* Will be shown after confirming correct product */}
			{confirmed &&
				<form onSubmit={handleFormSubmit}>
					<div className="ion-padding">
						<OptionList options={props.productInfo.options} handleOptionChange={handleOptionChange} optionState={productData.option}></OptionList>
						<IonList>
							<IonItem>
								<IonLabel position="fixed">數量</IonLabel>
								<IonInput type="number" name='quantity' onIonInput={handleInput} min="1" max="5" value={productData.quantity}></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel position="stacked">備注</IonLabel>
								<IonInput type="text" name="comment" onIonInput={handleInput} value={productData.comment}></IonInput>
								<IonNote slot="helper">有咩其他option 或者備注都可以寫係呢度</IonNote>
							</IonItem>
						</IonList>
					</div>
					<IonButton type='submit'>下一步</IonButton>
				</form>
			}
		</div>
	);
};

export default Product;
