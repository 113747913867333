import { useState } from 'react';
import React from 'react';
import {
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonButton
} from '@ionic/react';

interface FrontComponent {
    handleUrlSubmit: (param: string) => void,
}

const Front: React.FC<FrontComponent> = (props) => {
    // States
    const [url, setUrl] = useState<string>('');
    const [urlValid, setUrlValid] = useState<boolean>(true);

    const isValidUrl = (urlString: string) => {
        try {
            new URL(urlString);
            return true;
        } catch (err) {
            return false;
        }
    }

    const handleInput = (event: Event) => {
        const target = (event.target as HTMLInputElement)
        setUrlValid(isValidUrl(target.value));
        setUrl(target.value);
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        props.handleUrlSubmit(url)
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <IonList>
                    <IonItem className={`${urlValid} && 'ion-valid' ${urlValid === false && 'ion-invalid'}`}>
                        <IonLabel position="stacked">產品連結</IonLabel>
                        <IonInput placeholder="https://some.site/product" onIonInput={handleInput} value={url} type="url" required></IonInput>
                        <IonNote slot="helper">打開你想要條泳褲個page,直接copy 條link 落黎</IonNote>
                        <IonNote slot="error">佢應該生成咁 - https://some.site/product</IonNote>
                    </IonItem>
                </IonList>
                <IonButton type="submit">下一步</IonButton>

            </form>
        </div>
    );
};

export default Front;
